<template>
  <a-spin class="loadingClass" v-if="dataValue.uploading" tip="BOM上传中"></a-spin>
  <div v-if="!dataValue.uploading">
    <a-upload :file-list="dataValue.fileList" list-type="picture" :max-count="1" :before-upload="beforeUpload"
              @remove="handleRemove" class="aUpload">
      <a-button>
        <upload-outlined></upload-outlined>
        选择并上传一个BOM
      </a-button>
    </a-upload>
    <label class="lInfo">* 只可以上传excel文件。</label>

    <div class="upFileDiv">
      <a-button
          :disabled="dataValue.fileList.length === 0"
          :loading="dataValue.uploading"
          @click="allEvent.upEvent"
      >上传文件
      </a-button>
    </div>
  </div>

</template>

<script>

import {reactive} from "vue";
import proxy from "@/utils/axios";
import {message} from "ant-design-vue";


export default {
  name : "batch_up_file",
  emits : [ 'setMenuKey', 'goClose' ],
  setup(props, { emit }) {

    emit('setMenuKey', 'gj.plgj')


    let dataValue = reactive({
      fileList : [],
      uploading : false,
      filePath : ""

    })

    const handleRemove = file => {
      const index = dataValue.fileList.indexOf(file);
      const newFileList = dataValue.fileList.slice();
      newFileList.splice(index, 1);
      dataValue.fileList = newFileList;
    };

    const beforeUpload = file => {
      dataValue.fileList = [];
      dataValue.fileList = [ ...dataValue.fileList, file ];
      return false;
    };

    let allEvent = {

      upEvent : () => {

        let formData = new FormData();
        let file = dataValue.fileList[0];
        formData.append('batchFile', file)

        dataValue.uploading = true;
        proxy.postFile('/admin/price/batch', formData)
            .then((res) => {
              dataValue.uploading = false;
              if (res.code !== 200) {
                message.error(res.message);
              } else {
                dataValue.filePath = res.data['filePath'];
                emit( 'goClose', dataValue.filePath );
              }
            })
      }
    }


    return {
      dataValue, handleRemove, beforeUpload, allEvent
    }

  }

};
</script>

<style scoped>
.lInfo { margin-top: 30px; clear: both; float: left; text-align: center; width: 100%; }
.upFileDiv { text-align: center; padding-top: 30px; clear: both; }
.loadingClass { width: 100%; text-align: center; padding-top: 100px; }
</style>