<template>

  <a-spin class="loadingClass" v-if="dataValue.list.loading" tip="数据加载中 ..."></a-spin>
  <div v-if="!dataValue.list.loading">
    <div class="topSearch">
      <a-button @click="allEvent.upFile.open">{{dataValue.upEvent.upButtonName}}</a-button>
      <a-button v-if="dataValue.upEvent.upFilePath !== ''" @click="allEvent.download">下载BOM</a-button>
    </div>
    <div class="listDiv" v-if="dataValue.upEvent.upFilePath !== ''">
      <table class="ahuiTable">
        <tr>
          <th>编号</th>
          <th>型号</th>
          <th>厂商</th>
          <th>批号</th>
          <th>数量</th>
          <th>价格</th>
          <th>货币</th>
          <th>工厂库存价</th>
          <th>工厂销售价</th>
          <th>现货排单价</th>
          <th>贸易商价</th>
          <th></th>
        </tr>
        <tr v-for="aRow in dataValue.list.output.list" >
          <td class="min">{{aRow['indexNumber']}}</td>
          <td>{{aRow['modelName']}}</td>
          <td>{{aRow['manufacturerName']}}</td>
          <td class="min">{{aRow['batchName']}}</td>
          <td class="number">{{aRow['number']}}</td>
          <td class="price">{{ parseFloat(aRow['price']).toFixed(5)}}</td>
          <td class="min">{{aRow['moneyTypeName']}}</td>

          <td class="min" v-if="dataValue.list.output.showAssessStock !== 1"> - </td>
          <td class="price" v-if="dataValue.list.output.showAssessStock === 1">{{ aRow['assessC']['gckcPrice'].toFixed(5) }}</td>

          <td class="min" v-if="dataValue.list.output.showCashStock !== 1"> - </td>
          <td class="price" v-if="dataValue.list.output.showCashStock === 1">{{aRow['assessC']['gcxhPrice'].toFixed(5)}}</td>

          <td class="min" v-if="dataValue.list.output.showCashStock !== 1"> - </td>
          <td class="price" v-if="dataValue.list.output.showCashStock === 1">{{aRow['assessC']['gcpdPrice'].toFixed(5)}}</td>

          <td class="min" v-if="dataValue.list.output.showAssessMerchant !== 1"> - </td>
          <td class="price" v-if="dataValue.list.output.showAssessMerchant === 1">{{aRow['assessC']['mysPrice'].toFixed(5)}}</td>

          <td>{{aRow['rowValue']}}</td>
        </tr>
      </table>
    </div>
  </div>
  <a-drawer
      class="aDrawer"
      title="上传bom"
      width="232"
      :visible="dataValue.upEvent.showUpPage"
      :maskClosable="true"
      :destroyOnClose="true"
      placement="left"
      @close="allEvent.upFile.close"
  >
    <upFile @goClose="allEvent.upFile.close2" />

  </a-drawer>
</template>

<script>

import upFile from "@/view/tools/batch_up_file";
import {reactive} from "vue";
import httpRequest from "@/utils/axios";
import {message} from "ant-design-vue";

export default {
  name : "batch",
  components : {upFile},
  emits : [ 'setMenuKey' ],
  setup(props, { emit }) {
    emit('setMenuKey', 'gj.plgj')

    let dataValue = reactive({
        upEvent : {
          showUpPage : false,
          upFilePath : "",
          upButtonName : "上传一个BOM"
        },

        list : {
          loading : false,
          input : {
            filePath : "",
            isDownload : 0
          },
          output :{
            allRow : 0,
            showAssessStock : 0,
            showCashStock : 0,
            showAssessMerchant : 0,
            list : []
          }
        }
    });

    let allEvent = {

      upFile : {
        open : ()=>{
          dataValue.upEvent.showUpPage = true;
        },
        close : ()=>{
          dataValue.upEvent.showUpPage = false;
        },
        close2 :(filePath)=>{
          dataValue.upEvent.showUpPage = false;
          dataValue.upEvent.upFilePath = filePath;
          dataValue.upEvent.upButtonName = "重新上传";
          allEvent.getList();
        }
      },

      getList : ()=>{

        dataValue.list.loading = true;

        dataValue.list.input.filePath = dataValue.upEvent.upFilePath;
        dataValue.list.input.isDownload = 0;

        httpRequest.post('/admin/price/batch_show_c', dataValue.list.input)
            .then((res) => {
              dataValue.list.loading = false;
              if (res.code !== 200) {
                message.error(res.message);
              } else {
                dataValue.list.output.allCount = res['data']['allRow'];
                dataValue.list.output.showAssessStock = res['data']['showAssessStock'];
                dataValue.list.output.showCashStock = res['data']['showCashStock'];
                dataValue.list.output.showAssessMerchant = res['data']['showAssessMerchant'];
                dataValue.list.output.list = res['data']['list'];
              }
            })
      },

      download : ()=>{

        dataValue.list.input.filePath = dataValue.upEvent.upFilePath;
        dataValue.list.input.isDownload = 1;

        httpRequest.postDownload('/admin/price/batch_show_c', dataValue.list.input)
            .then(() => {
              message.destroy();
            })
      }
    }

    return{
      dataValue, allEvent
    }
  }
};
</script>

<style scoped>
  .topSearch{ padding: 15px 15px 0 15px; }
  .topSearch button{ margin-right: 20px; }
  .loadingClass { width: 100%; text-align: center; padding-top: 100px; }
  .listDiv{ padding: 15px 15px 0 15px;  }
</style>